:root {
  --main-bg-color: #023f61;
}
.bg_color {
  background-color: var(--main-bg-color) !important;
}
.btn_bg_color {
  background-color: var(--main-bg-color) !important;
  color: #fff;
  width: 100%;
}
.title_color {
  color: var(--main-bg-color) !important;
}
.employe {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #023f61;
  padding: 5px;
}
.tagify {
  width: 100%;
  height: auto !important;
  border-radius: 5px;
  /* max-width: 767px; */
}
.modal-dialog {
  display: flex;
  /* max-width: 70%; */
}
.resume_pr {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
@media (max-width: 765px) {
  .resume_pr {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-top: 20px;
  }
}
.userphone .react-tel-input input[type="tel"] {
  height: 37px;
  width: 100%;
  border-radius: 5px;
}
.candidatephone .react-tel-input input[type="tel"] {
  height: 37px;
  width: 100%;
  border-radius: 5px;
}
.accountphone .react-tel-input input[type="tel"] {
  height: 37px;
  width: 100%;
  border-radius: 5px;
}
.contactphone .react-tel-input input[type="tel"] {
  height: 37px;
  width: 100%;
  border-radius: 5px;
}
.usertitle {
  font-family: "Roboto";
  font-style: normal;
  color: #627d8c;
}
.userprofile {
  box-sizing: border-box;
  width: 140px;
  height: 140px;
  border-radius: 50px;
  margin: auto;
}
.photo {
  position: absolute;
  left: 426px;
  top: 113px;
  cursor: pointer;
}
#emailavtar {
  width: 26px;
  height: 26px;
  background: #8a9ea9;
  color: white;
  border-radius: 22px;
  text-align: center;
}
.navtext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #222222;
  padding-left: 10px;
}
#notification {
  background-color: var(--main-bg-color);
}
.userdetail {
  padding-top: 23px;
}
.cancel {
  width: 99px;
  height: 39px;
  color: #6a6a6a;
  background-color: #e5e5e5;
  border-radius: 4px;
}
#userdetailtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #627d8c;
  background-color: #f9fdff;
}
.save {
  width: 99px;
  height: 39px;
  color: #ffffff;
  background-color: #1a7a4e;
  border-radius: 4px;
}
.autofill_btn {
  width: auto;
  height: auto;
  padding: 0.3rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  font-size: 15px;
  color: #ffffff;
  background-color: #1a7a4e;
  border-radius: 4px;
}
.file {
  display: flex;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-bottom: 4px;
  line-height: 16px;
}
/* input[type="file"] {
  position: absolute;
  top: 26.6rem;
  height: 5.9rem;
  width: 22rem;
  left: 2rem;
  opacity: 0;
  cursor: pointer;
  outline: none;
} */
#file-view {
  position: absolute;
  top: 31.6rem;
  height: 5.9rem;
  width: 39.7rem;
  left: 28rem;
  opacity: 0;
  cursor: pointer;
  outline: none;
}
#submission-file-input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  outline: none;
  height: 6rem;
  width: 100%;
  top: -65px;
}
#candidate-file-input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  outline: none;
  height: 6rem;
  width: 100%;
  top: -65px;
}
#offerletter-file-input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  outline: none;
  height: 6rem;
  width: 100%;
  top: -65px;
}
#workorder-file-input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  outline: none;
  height: 6rem;
  width: 100%;
  top: -65px;
}
#otherdocuments-file-input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  outline: none;
  height: 6rem;
  width: 100%;
  top: -65px;
}
#edit-resume {
  position: absolute;
  top: 26.7rem;
  height: 5.9rem;
  width: 22.3rem;
  left: 1.8rem;
  opacity: 0;
  cursor: pointer;
  outline: none;
}
#user_image {
  position: absolute;
  height: 3.2rem;
  width: 3.2rem;
  top: 0rem;
  opacity: 0;
  cursor: pointer;
  outline: none;
}
.file-dummy {
  width: 100%;
  height: 6rem;
  /* padding: 30px; */
  background-color: #f8f9fc;
  border: 1px dashed #cfcfcf;
  text-align: center;
  transition: 0.3s ease-in-out;
}
.success {
  display: none;
}
.file-dummy:hover {
  background: rgba(255, 255, 255, 0.1);
}
input[type="file"]:valid + .file-dummy {
  border-color: rgba(0, 255, 0, 0.4);
  background-color: rgba(0, 255, 0, 0.3);
}
.default {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  padding-top: 2rem;
}
.drag {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 17px;
  letter-spacing: 0.3px;
  line-height: 16px;
}
.browse {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  color: green;
}
#profileImage {
  position: absolute;
  top: 5.2rem;
  height: 4.9rem;
  width: 5rem;
  left: 1.6rem;
  opacity: 0;
  cursor: pointer;
  outline: none;
}
.iconbox {
  width: auto;
  height: 80px;
  background-color: #ffffff;
  color: #7b9aaa;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  margin: auto 44px 9px 38px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.activeiconbox {
  width: auto;
  height: 80px;
  background-color: var(--main-bg-color);
  color: #ffffff;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  margin: auto 44px 9px 38px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.icontext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  padding-top: 2px;
  color: #7b9aaa;
}
.activeicontext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  padding-top: 2px;
  color: #ffffff;
}
#checkbox {
  cursor: pointer;
  font-size: 15px;
  margin-right: 8px;
}
#checkbox:checked {
  background-color: var(--main-bg-color);
  border: none;
}
.timeline {
  border-left: 2px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
  padding: 32px;
}
.timeline .timeline-item {
  position: relative;
}
.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}
.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -40.5px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  content: "";
}
.activitytitle {
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1.2;
}
.activityinfo {
  font-family: "Open Sans", sans-serif;
  display: flex;
  position: absolute;
  top: 0rem;
  left: -9rem;
  font-size: 0.8rem;
  color: black;
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 2rem;
  cursor: pointer;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--main-bg-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-loader {
  width: 40px;
  height: 40px;
  --c: linear-gradient(#023f61 0 0);
  --r1: radial-gradient(farthest-side at bottom, #023f61 93%, #0000);
  --r2: radial-gradient(farthest-side at top, #023f61 93%, #0000);
  background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2),
    var(--c), var(--r1), var(--r2);
  background-repeat: no-repeat;
  animation: db2 1s infinite alternate;
}

@keyframes db2 {
  0%,
  25% {
    background-size:
      8px 0,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px;
    background-position:
      0 50%,
      0 calc(50% - 2px),
      0 calc(50% + 2px),
      50% 50%,
      50% calc(50% - 2px),
      50% calc(50% + 2px),
      100% 50%,
      100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  50% {
    background-size:
      8px 100%,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px;
    background-position:
      0 50%,
      0 calc(0% - 2px),
      0 calc(100% + 2px),
      50% 50%,
      50% calc(50% - 2px),
      50% calc(50% + 2px),
      100% 50%,
      100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  75% {
    background-size:
      8px 100%,
      8px 4px,
      8px 4px,
      8px 100%,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px;
    background-position:
      0 50%,
      0 calc(0% - 2px),
      0 calc(100% + 2px),
      50% 50%,
      50% calc(0% - 2px),
      50% calc(100% + 2px),
      100% 50%,
      100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  95%,
  100% {
    background-size:
      8px 100%,
      8px 4px,
      8px 4px,
      8px 100%,
      8px 4px,
      8px 4px,
      8px 100%,
      8px 4px,
      8px 4px;
    background-position:
      0 50%,
      0 calc(0% - 2px),
      0 calc(100% + 2px),
      50% 50%,
      50% calc(0% - 2px),
      50% calc(100% + 2px),
      100% 50%,
      100% calc(0% - 2px),
      100% calc(100% + 2px);
  }
}

.custom-form-label {
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #6c7b88;
  display: flex;
  margin-bottom: 4px;
  line-height: 16px;
  padding: 0;
}

.update_button {
  width: 99px;
  height: 39px;
  color: #ffffff;
  background-color: var(--main-bg-color);
  border-radius: 4px;
}

#users_range {
  width: 360px;
  background-color: white;
}
#candidate-file-inp {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  left: 0px;
  opacity: 0;
  cursor: pointer;
  outline: none;
}
.close_icon {
  width: 33px;
  background-color: #627d8c;
  color: #f0f0f0;
  position: absolute;
  top: -1rem;
  right: 0rem;
  border-radius: 16px;
}
@media (max-width: 765px) {
  .close_icon {
    position: relative;
    top: 1rem;
    right: 0.7rem !important;
    left: -0.7rem !important;
  }
}
.content_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* width: 85%; */
  margin-left: auto;
  transition: width 0.3s ease;
}
.content_details_nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  transition: width 0.4s ease;
}

@media (max-width: 765px) {
  .content_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px 15px;
  }
}
@media (max-width: 1439px) {
  .content_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px 15px;
  }
}
.horizontal_line {
  width: 68px;
  transform: rotate(90deg);
}
.box_details {
  display: flex;
  align-items: center;
  padding: 5px;
  flex-wrap: wrap;
}
.back_arrow {
  font-size: 24px;
  /* position: relative; */
  width: 35px;
  /* top: 2.4rem; */
  /* left: 18.9rem; */
  display: flex;
  color: white;
  cursor: pointer;
  transition: left 0.3s ease;
}
.back_arrow_nav {
  font-size: 24px;
  /* position: relative; */
  width: 35px;
  /* top: 2.4rem; */
  /* left: 2.5rem; */
  display: flex;
  color: #fff;
  cursor: pointer;
  transition: left 0.3s ease;
}
.content_title {
  width: 21rem;
}
@media (max-width: 1434px) {
  .back_arrow {
    left: 2rem;
  }
}
@media (max-width: 768px) {
  .horizontal_line {
    display: none;
  }
  .box_details {
    justify-content: center;
  }
  .back_arrow {
    left: 38rem !important;
  }
  .content_title {
    width: auto;
  }
  #btn_clear {
    color: var(--main-bg-color) !important;
  }
  #btn_clear:hover {
    color: white !important;
  }
  .job_summary {
    color: var(--main-bg-color) !important;
    font-weight: bold;
  }
  .job_box {
    flex-direction: column !important;
    gap: 1rem !important;
  }
  .table_box {
    padding: 10px;
    background-color: white;
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 0.5%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem !important;
    justify-content: flex-start !important;
  }
}
@media (max-width: 653px) {
  .back_arrow {
    left: 34rem !important;
  }
}

@media (max-width: 600px) {
  .back_arrow {
    left: 30rem !important;
  }
}
@media (max-width: 524px) {
  .back_arrow {
    left: 24rem !important;
  }
}
@media (max-width: 433px) {
  .back_arrow {
    left: 20rem !important;
  }
}
.loader_import {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 9999;
  background: conic-gradient(#0000 10%, #023f61);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
.resume_screen_btn {
  width: 180px !important;
  height: 39px !important;
  color: #ffffff !important;
  background-color: #1a7a4e !important;
  border-radius: 4px !important;
}
.linkedin_btn {
  width: auto;
  height: auto;
  padding: 0.5rem;
  color: #ffffff;
  background-color: var(--main-bg-color);
  border-radius: 4px;
}
.copy_btn {
  width: auto;
  height: 39px;
  padding: 0px 15px 0px 15px;
  color: #6a6a6a;
  background-color: #e5e5e5;
  border-radius: 4px;
}
.open_In_google_btn {
  width: auto;
  height: 39px;
  color: #ffffff;
  padding: 0px 15px 0px 15px;
  background-color: #1a7a4e;
  border-radius: 4px;
}
.note_btn {
  font-size: 11px;
  padding: 0px 1px;
  text-align: center;
  width: 14px;
  height: 14px;
  color: #85898c;
  background-color: #d6d6d6;
  border-radius: 3px;
  line-height: 14px;
  font-weight: bold;
  margin-right: 8px;
}
.addnote_btn {
  background-color: var(--main-bg-color) !important;
  color: white;
  padding: 3px 10px 3px 10px;
  border-radius: 7px;
}
.nt_box {
  width: 100%;
  height: auto;
  background: #e9e9e9;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 1rem;
}
.note_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.note_b {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.note_icon_box {
  background: white;
  width: fit-content;
  padding: 10px;
  border-radius: 24px;
}
.notes_detail {
  padding: 10px;
  color: grey;
}
#add_note {
  display: none;
}
.table_box {
  padding: 10px;
  background-color: white;
  /* width: 100%; */
  height: auto;
  border-radius: 5px;
  margin-bottom: 0.5%;
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: space-between; */
}
.button_box {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
}
.btns_box {
  width: 100%;
  height: auto;
  background-color: white;
  border-top: 1px solid #d4cccc;
  padding: 1rem;
  position: sticky;
  bottom: 0;
}
.candi_btns {
  width: auto;
  height: auto;
  background-color: var(--main-bg-color) !important;
  color: white;
  border: 1px solid #d4cccc;
  padding: 4px 15px 4px 15px;
  border-radius: 5px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0.4px;
}
.team_box {
  width: 30.9rem;
  height: auto;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 12px;
}
.table_menu {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.team_text {
  color: darkgrey;
  font-weight: bold;
  font-size: 15px;
}
.react-tel-input .form-control {
  width: 100% !important;
}
@media (max-width: 768px) {
  .team_box {
    width: auto !important;
    height: auto;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 12px;
  }
  .button_box {
    margin-top: 1rem !important;
  }
}
.nowrap_content {
  text-wrap: nowrap !important;
  color: #718096 !important;
}
.list_view {
  cursor: pointer;
  font-size: 17px !important;
  margin-right: 5px;
  margin-left: 10px;
}
.resume_ld {
  margin-top: 5rem;
}
#candidate-file-inp-jobview {
  position: absolute;
  right: 27px;
  top: 30px;
  width: 129px;
  opacity: 0;
  cursor: pointer;
  outline: none;
}
.content_total_details {
  margin-left: 300px;
  position: relative;
  margin-top: 5rem;
  margin-right: 30px;
}
@media (max-width: 768px) {
  .content_total_details {
    margin-left: 22px !important;
    position: relative;
    margin-top: 5rem;
    margin-right: 22px !important;
  }
}
@media (max-width: 1439px) {
  .content_total_details {
    margin-left: 22px !important;
    position: relative;
    margin-top: 5rem;
    margin-right: 22px !important;
  }
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_loader {
  width: 55px;
  height: 55px;
  border: 5px solid var(--main-bg-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.main_loader:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid var(--main-bg-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}
.box_flex {
  margin: 2rem !important;
}
.search_box {
  width: 30%;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  /* z-index: 9999; */
}
.candidate_compo {
  padding-top: 50px;
}
.pcandidate_compo {
  padding-top: 100px;
}
.search_input {
  padding: 9px !important;
  padding-left: 2.6rem !important;
  color: #7b9aaa !important;
  background-color: white !important;
  font-family: Roboto !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  font-size: 15px !important;
}
.search_button {
  color: white !important;
  border: 1px solid white !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
  font-size: 14px !important;
  background-color: var(--main-bg-color) !important;
  box-shadow: none !important;
}
.search_button:hover {
  background-color: white !important;
  color: var(--main-bg-color) !important;
  font-weight: 500 !important;
}
@media (max-width: 768px) {
  .search_box {
    width: 100% !important;
    border-radius: 5px !important;
    margin-top: 60px !important;
    margin-bottom: 30px !important;
    display: flex !important;
    gap: 10px !important;
    justify-content: center !important;
  }
  .candidate_compo {
    padding-top: 0px !important;
  }
}
.democlass {
  border-color: red !important;
}
.democlass__control {
  border-color: red !important;
}

.box-center {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
}

.addcandidatemodelform {
  height: 88vh;
  overflow: auto;
}

.selectDropDown {
  width: 100%;
}

.ReactSelectClass {
  width: 250px;
}

.reactMultiSelectMenu {
  z-index: 100000 !important;
  width: 280px;
}
.reactDatePickerRange{
  z-index: 100000 !important;
}